import "@rainbow-me/rainbowkit/styles.css";
import {getDefaultWallets, RainbowKitProvider,darkTheme} from "@rainbow-me/rainbowkit";
import {
  chain,
  configureChains,
  createClient,
  WagmiConfig,
} from 'wagmi';
import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public';
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";

import App from "App";

const BSC = {
    id: 56,
    name: "Binance Smart Chain",
    network: "Binance Smart Chain",
    nativeCurrency: {
      decimals: 18,
      name: "BNB",
      symbol: "BNB",
    },
    rpcUrls: {
      default: "https://bscrpc.com",
    },
    iconUrl:'https://bscscan.com/images/svg/brands/bnb.svg?v=1.3',
    blockExplorers: {
      default: {
        name: "Binance Smart Chain Scan",
        url: "https://bscscan.com/",
      },
    },
    testnet: false,
  };


  const { chains, provider } = configureChains(
    [BSC],
    [
      publicProvider(),
    ]
    );

const { connectors } = getDefaultWallets({
appName: 'Liux',
chains
});
const wagmiClient = createClient({
autoConnect: true,
connectors,
provider
})

export  const Root = () => {
  return (
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider chains={chains} theme={darkTheme()} showRecentTransactions={true} coolMode>
        <App />
      </RainbowKitProvider>
    </WagmiConfig>
  );
};
