
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";

// Images
import team2 from "assets/images/team-2.jpg";
import img1 from "../../../assets/myimg/1.png"
import img2 from "../../../assets/myimg/2.png"
import img3 from "../../../assets/myimg/3.png"
import img4 from "../../../assets/myimg/4.png"
import img5 from "../../../assets/myimg/5.png"
import img6 from "../../../assets/myimg/6.png"
import img7 from "../../../assets/myimg/7.png"
import img8 from "../../../assets/myimg/8.png"
import img9 from "../../../assets/myimg/9.png"
import img10 from "../../../assets/myimg/10.png"
import "./index.css"


export default function data() {
  
  const Author = ({ image, name, email }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar src={image} name={name} size="sm" />
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {name}
        </MDTypography>
        <MDTypography variant="caption">{email}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ title, description }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        {title}
      </MDTypography>
      <MDTypography variant="caption">{description}</MDTypography>
    </MDBox>
  );

  return {
    columns: [
      { Header: "Collection", accessor: "author", width: "45%", align: "left" },
      { Header: "All Time Volume", accessor: "function", align: "left" },
      { Header: "All Time", accessor: "status", align: "center" },
      { Header: "Floor", accessor: "employed", align: "center" },
      { Header: "Owners", accessor: "action", align: "center" },
      { Header: "Supply", accessor: "myadd", align: "center" },
    ],

    rows: [
      {
        author: <Author image={img1} name="CryptoPunks"  />,
        function: "1,063,200.64ETH",
        status: <span className="up">+1,063,200.64%</span>,
        employed:<span>65.500</span> ,
        action:<span>36k</span> ,
        myadd: <span>1000k</span>
      },
      {
        author: <Author image={img2} name="Bored Ape Yacht Club"  />,
        function: <span>687,816.49ETH</span>,
        status: <span className="up">+687,816.49%</span>,
        employed:<span>52.250</span> ,
        action:<span>6.4k</span> ,
        myadd: <span>10k</span>
      },
      {
        author: <Author image={img3} name="Mutant Ape Yacht Club"  />,
        function: <span>464,894.01ETH</span>,
        status: <span className="up">+464,894.01%</span>,
        employed:<span>11.600</span> ,
        action:<span>12.9k</span> ,
        myadd: <span>19.4k</span>
      },
      {
        author: <Author image={img4} name="Art Blocks"  />,
        function: <span>455,152.84ETH</span>,
        status: <span className="up">+455,152.84%</span>,
        employed:<span>0.060</span> ,
        action:<span>35.9k</span> ,
        myadd: <span>265k</span>
      },
      {
        author: <Author image={img5} name="Otherdeed for Otherside"  />,
        function: <span>356,576.62ETH</span>,
        status: <span className="up">+356,576.62%</span>,
        employed:<span>0.859</span> ,
        action:<span>34.1k</span> ,
        myadd: <span>100.0k</span>
      },
      {
        author: <Author image={img6} name="Azuki"  />,
        function: <span>268,421.97ETH</span>,
        status: <span className="up">+268,421.97%</span>,
        employed:<span>9.427</span> ,
        action:<span>5.0k</span> ,
        myadd: <span>10.0k</span>
      },
      {
        author: <Author image={img7} name="CLONE X - X TAKASHI MURAKAMI"  />,
        function: <span>239,137.84ETH</span>,
        status: <span className="up">+239,137.84%</span>,
        employed:<span>7.000</span> ,
        action:<span>9.6k</span> ,
        myadd: <span>19.4k</span>
      },
      {
        author: <Author image={img8} name="Moonbirds"  />,
        function: <span>174,559.90ETH</span>,
        status: <span className="up">+174,559.90%</span>,
        employed:<span>7.340</span> ,
        action:<span>6.6k</span> ,
        myadd: <span>10.0k</span>
      },
      {
        author: <Author image={img9} name="The SandBox"  />,
        function: <span>158,423.39ETH</span>,
        status: <span className="up">+158,423.39%</span>,
        employed:<span>1.187</span> ,
        action:<span>22.2k</span> ,
        myadd: <span>164.4k</span>
      },
      {
        author: <Author image={img10} name="Doodles"  />,
        function: <span>151,704.86ETH</span>,
        status: <span className="up">+151,704.86%</span>,
        employed:<span>5.700</span> ,
        action:<span>5.5k</span> ,
        myadd: <span>10.0k</span>
      },
      
      
    ],
  };
}
