

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import AirplayIcon from '@mui/icons-material/Airplay';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import SearchIcon from '@mui/icons-material/Search';
import authorsTableData from "layouts/tables/data/authorsTableData";
import "./index.css"

function Tables() {
  const { columns, rows } = authorsTableData();

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <div className="headernft">
          <span>The Best </span><span id="span2"> NFT Aggregator</span>
      </div>
      <div className="tandm">
          <div id="t">
            <AirplayIcon fontSize="large" color="white"></AirplayIcon>
            <span id="tspan">Trending</span>
          </div>
          <div id="m">
              <AddTaskIcon fontSize="large" ></AddTaskIcon>
              <span id="mspan">Mints</span>
          </div>
      </div>
      <div className="add">
        <div className="change">
              <ChangeCircleIcon></ChangeCircleIcon>
              <span id="cspan1">100 results</span>
              <span id="cspan2">1 minute</span>
        </div>
        <div className="iinput">
          <input></input>
          <div id="searchicon">
          <SearchIcon fontSize="large"></SearchIcon>
          </div>
          
        </div>
        <div className="lasttime">
          <div className="tbox" id="t1">
            <span id="t1">All Time</span>
          </div >
          <div className="tbox" id="t2">
            <span id="t2">30 D</span>
          </div >
          <div className="tbox" id="t3">
            <span id="t3">7 D</span>
          </div >
          <div className="tbox" id="t4">
            <span id="t4">24 H</span>
          </div >
         
        </div>
      </div>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>

              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
