
import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import "./index.css"


function Launchpad() {
  const [successSB, setSuccessSB] = useState(false);
  const [infoSB, setInfoSB] = useState(false);
  const [warningSB, setWarningSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);
  const openInfoSB = () => setInfoSB(true);
  const closeInfoSB = () => setInfoSB(false);
  const openWarningSB = () => setWarningSB(true);
  const closeWarningSB = () => setWarningSB(false);
  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const alertContent = (name) => (
    <MDTypography variant="body2" color="white">
      A simple {name} alert with{" "}
      <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
        an example link
      </MDTypography>
      . Give it a click if you like.
    </MDTypography>
  );

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="12 mins ago"
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderInfoSB = (
    <MDSnackbar
      icon="notifications"
      title=" Dashboard"
      content="Hello,You need to complete the previous steps."
      dateTime="12 mins ago"
      open={infoSB}
      onClose={closeInfoSB}
      close={closeInfoSB}
    />
  );

  const renderWarningSB = (
    <MDSnackbar
      color="warning"
      icon="star"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={warningSB}
      onClose={closeWarningSB}
      close={closeWarningSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Material Dashboard"
      content="Hello, world! This is a notification message"
      dateTime="11 mins ago"
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5">Liux Launchpad</MDTypography>
                <MDTypography variant="button" color="text" fontWeight="regular">
                  The project party can launch in Glaze Portal according to the following steps.
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="info" onClick={openSuccessSB} fullWidth>
                      Verify Token
                    </MDButton>
                    {/* {renderSuccessSB} */}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="dark" onClick={openInfoSB} fullWidth>
                      Defi Launchpad info
                    </MDButton>
                    {/* {renderInfoSB} */}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="dark" onClick={openWarningSB} fullWidth>
                      additional  info
                    </MDButton>
                    {/* {renderWarningSB} */}
                  </Grid>
                  <Grid item xs={12} sm={6} lg={3}>
                    <MDButton variant="gradient" color="dark" onClick={openErrorSB} fullWidth>
                      confrim
                    </MDButton>
                    {/* {renderErrorSB} */}
                  </Grid>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid item xs={12} lg={8}>
            <Card>
              <div className="launchpad">
                <p className="tip">Note: * is required</p>
                <p className="tokenaddress"> * Token Address</p>
                <button className="tokencreate">CreateToken</button>
                <input className="tokeninput"></input>
                <p className="fee"> Pool creation fee: 100Liux</p>
                <p className="blanktip">Token address cannot be blank</p>
                <p className="currency">Currency</p>
                <form>
                  <input type="radio" name="token" value=" BNB" checked /> BNB
                  <br />
                  <input type="radio" name="token" value="BUSD" />BUSD
                  <br />
                  <input type="radio" name="token" value="USDT" />USDT
                  <br />
                  <input type="radio" name="token" value="USDC" />USDC
                </form>
                <p className="fee">Users will paly with BNB for your token.</p>

                <p className="feeop">Fee Options</p>

                <form>
                  <input type="radio" name="fee" value="3r" checked />3% BNB raised only <span className="recommended">(recommended)</span>
                  <br />
                  <input type="radio" name="fee" value="11" />1% BNB raised + 1% token sold
                </form>

                <p className="feeop"> List Options</p>
                <form>
                  <input type="radio" name="op" value="auto" checked />Auto Listing
                  <br />
                  <input type="radio" name="op" value="manual" />Manual Listing
                </form>

                <p id="lastp">For auto listing, after you finalize the pool your token will be auto listed on DEX/GLAZE</p>
                <div className="next">
                <button>Next</button>
                </div>
                

              </div>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Launchpad;
