import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import "./index.css"
import { Button, Card } from "@mui/material";
import exchange from "../../assets/myimg/exchange.svg"
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect, useRef, useState } from "react";
import { ethers } from "ethers";
import contractAddress from "contracts/Address/Address";
import contractAbi from "contracts/ABI/Abi";
import { connect } from "@wagmi/core";
import {
  useConnectModal,
  useAccountModal,
  useChainModal,
} from '@rainbow-me/rainbowkit';
import bnblogo from "../../assets/myimg/bnb.png"
import gla from "../../assets/myimg/logo.png"


// 合约和钱包数据
const WBNB = "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c";
const provider = new ethers.providers.Web3Provider(window.ethereum);
const signer = provider.getSigner();
const addresscurr = window.ethereum.selectedAddress;



// GLP合约信息
const contractGLP = new ethers.Contract(contractAddress.GLP, contractAbi.ERC20, provider);
const GLPWithSigner = contractGLP.connect(signer);

// 路由合约信息
const contractRouter = new ethers.Contract(contractAddress.Router, contractAbi.Router, provider);
const RouterWithSigner = contractRouter.connect(signer);

// 获取BNB余额
async function getBNBBalance() {
  const res = await provider.getBalance(addresscurr);
  
  return ethers.utils.formatEther(res)
}



// 获取GLP余额
async function getGLPBalance() {
  const res = await contractGLP.balanceOf(addresscurr);
  return ethers.utils.formatEther(res)
}

// 路径
const path1 = [WBNB, contractAddress.GLP]
const path2 = [contractAddress.GLP, WBNB]

// 换算价格
async function AmountsOut(value,path) {
  if (value === "") { return 0 }
  const res = await contractRouter.getAmountsOut(ethers.utils.parseEther(value), path)
  return ethers.utils.formatEther(res[1])
}

// 封装数据
function getOverride(amountIn) {
  const overrides = {
    gasLimit: 3000000,
    gasPrice: ethers.utils.parseUnits('9.0', 'gwei'),
    value: amountIn
  }
  return overrides
}

// 交换方法
async function swap1(amountIn, amountOutMin) {
  console.log(amountIn)
  if (amountIn === "") return
  const tx = await RouterWithSigner.swapExactETHForTokens(0, path1, addresscurr, 22266956318, getOverride(amountIn))
  res = await tx.wait()

}
async function swap2(amountIn, amountOutMin) {

  if (amountIn === "") return
  const tx = await RouterWithSigner.swapExactTokensForETH(amountIn,0,path2, addresscurr, 22266956318)
  res = await tx.wait()
}

async function approve(amount){
  if (amount === "") return
  if (amount === 0) return
  const tx = await GLPWithSigner.approve(contractAddress.Router,ethers.utils.parseEther(amount))
}


function Swap() {
  const [bnbbalance, setBnbbalance] = useState(0)
  const [glpbalance, setGlpbalance] = useState(0)

  const [input2value, setInput2value] = useState(0)
  const [input22value, setInput22value] = useState(0)
  const input1 = useRef()
  const input11 = useRef()

  const Balance = async () => {
    const bnb = await getBNBBalance()
    const glp = await getGLPBalance()
    const res1 = bnb.slice(0, bnb.indexOf(".") + 4)
    console.log(res1)
    const res2 = glp.slice(0, glp.indexOf(".") + 4)
    setBnbbalance(res1)
    setGlpbalance(res2)
  }


  const GetInput1 = async () => {
    const temp = await AmountsOut(input1.current.value,path1)
    setInput2value(temp)
  }

  const GetInput2 = async () => {
    const temp = await AmountsOut(input11.current.value,path2)
    setInput22value(temp)
  }

  useEffect(() => {
    Balance()
  })


  return (
    <DashboardLayout>
      {/* 浮动导航栏 */}
      <DashboardNavbar />
      <MDBox py={3}>
        <MDBox mt={4.5} className="swaper">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}  >
              <MDBox mb={3}>
                <Card className="swaperinner">
                  <div>
                    <p className="swap1">Swapping</p>
                    <p className="swap2">Convert your BNB to GLP or view versa, Easiest and fastest way to convert your asserts.</p>
                  </div>
                  <div className="swapinput">
                    <input placeholder="Enter Amount" className="input1" ref={input1} onChange={GetInput1}></input>
                    <img src={bnblogo} alt="bnblogo" id="bnblogo"></img>
                    <input placeholder="Your Receive" value={input2value} className="input1" ></input>
                    <img src={gla} alt="gla" id="gla"></img>

                  </div>
                  <div className="balance">
                    <p>
                      <span className="tokenname">Your BNB Balance</span>
                      <span className="tokenvalue">{bnbbalance}BNB</span>
                    </p>
                    <p>
                      <span className="tokenname">Your Liux Balance</span>
                      <span className="tokenvalue">{glpbalance}Liux</span>
                    </p>
                  </div>
                  {/* <div className="swapbtnbox">
                    {openConnectModal && (
                      <button onClick={openConnectModal} type="button" className="swapbtn">
                        Connect
                      </button>
                    )}
                  </div> */}
                  <div className="swapbtnbox">
                    <button className="swapbtn" onClick={() => swap1(ethers.utils.parseEther(input1.current.value), ethers.utils.parseEther(input2value))} >Swap</button>
                  </div>
                </Card>
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} className="swapbox">
              <MDBox mb={3}>
                <Card className="swaperinner">
                  <div>
                    <p className="swap1">Swapping</p>
                    <p className="swap2">Convert your BNB to Liux or view versa, Easiest and fastest way to convert your asserts.</p>
                  </div>
                  <div className="swapinput">
                    <input placeholder="Enter Amount" className="input1" ref={input11}  ></input>
                    <button className="approve" onClick={()=>approve(input11.current.value)}>Approve</button>
                    <p id="tip1">you need approve first.</p>
                    <input placeholder="Your Receive" value={input22value} className="input1" ></input>
                    <img src={gla} alt="gla" id="gla1"></img>
                    <img src={bnblogo} alt="bnblogo" id="bnblogo1"></img>

                  </div>
                  <div className="balance">
                    <p> <span className="tokenname">Your BNB Balance</span> <span className="tokenvalue">{bnbbalance}BNB</span></p>
                    <p> <span className="tokenname">Your Liux Balance</span> <span className="tokenvalue">{glpbalance}Liux</span></p>
                  </div>
                  {/* <div className="swapbtnbox">
                    {openConnectModal && (
                      <button onClick={openConnectModal} type="button" className="swapbtn">
                        Connect
                      </button>
                    )}
                  </div> */}
                  <div className="swapbtnbox">
                    <button className="swapbtn" onClick={() => swap2(ethers.utils.parseEther(input11.current.value), ethers.utils.parseEther(input22value))} >Swap</button>
                  </div>
                </Card>
              </MDBox>
            </Grid>

          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Bookings"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Today's Users"
                count="2,300"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Revenue"
                count="34k"
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Followers"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Swap;
