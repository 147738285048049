import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

// Material Dashboard 2 React Context Provider
import { MaterialUIControllerProvider } from "context";
import { Root } from "Root";

// 这里不需要动
ReactDOM.render(
  <BrowserRouter>
    <MaterialUIControllerProvider>
      <Root />
    </MaterialUIControllerProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
