import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";
import Billing from "layouts/billing";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";

// @mui icons
import Icon from "@mui/material/Icon";
import Launchpad from "layouts/Launchpad";

// 路由组件  在侧边栏添加内容  就需要在这里添加路由注
const routes = [

  
  {
    type: "collapse",
    name: "Swap",
    key: "Swap",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/swap",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Chart",
    key: "chart",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/chart",
    component: <Billing />,
  },
  {
    type: "collapse",
    name: "NFT",
    key: "NFT",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/nft",
    component: <Tables />,
  },

  // {
  //   type: "collapse",
  //   name: "Perpetual Futures Contracts",
  //   key: "profile",
  //   icon: <Icon fontSize="small">person</Icon>,
  //   route: "/profile",
  //   component: <Profile />,
  // },
  {
    type: "collapse",
    name: "Liux Launchpad",
    key: "launchpad",
    icon: <Icon fontSize="small">login</Icon>,
    route: "/launchpad",
    component: <Launchpad />,
  },
  {
    type: "collapse",
    name: "Launch List",
    key: "launchlist",
    icon: <Icon fontSize="small">notifications</Icon>,
    route: "/launchlist",
    component: <Notifications />,
  },

  
  {
    type: "collapse",
    name: "Settings",
    key: "sign-up",
    icon: <Icon fontSize="small">assignment</Icon>,
    route: "/settings",
    component: <Notifications />,
  },
];

export default routes;
