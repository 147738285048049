import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MasterCard from "examples/Cards/MasterCard";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Billing page components
import PaymentMethod from "layouts/billing/components/PaymentMethod";
import Invoices from "layouts/billing/components/Invoices";
import BillingInformation from "layouts/billing/components/BillingInformation";
import Transactions from "layouts/billing/components/Transactions";
import "./index.css"

function Billing() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <DashboardNavbar absolute isMini /> */}
      <MDBox mt={8}>
        <MDBox mb={3}>
          <Grid container spacing={3} className="box1">
            
              <div>
              </div>
            <div className="box" >
              <iframe src="https://charts.bogged.finance/?c=bsc&t=0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"
                frameborder="0" scrolling="no"  allowTransparency="true" 
                width="110%" height="100%"
                className="kline"
              >
              </iframe>
            </div>
         
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Billing;
